.SupportBox-container {
  margin: 0;
  background-color: #018b9d;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  // flex-direction: column;

  color: white;

  padding: 20px 0;
  gap: 20px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  > h1 {
    padding: 0;
    margin: 0;
    font-weight: 800;
    font-size: 18px;
  }

  &.darker {
    background-color: #083e53;
  }
  button {
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    transition: 0.4s all;
    font-size: 21px;
    font-weight: 800;

    &:hover {
      border: 2px solid rgba(255, 255, 255, 1);
    }
  }
}
