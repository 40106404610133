.Schedule {
  padding: 5px;
  button {
    margin-bottom: 20px;
  }

  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    font-size: 14px;

    li {
      opacity: 0.5;
      padding: 3px;
      border: 1px solid rgba(black, 0);
      transition:
        0.4s opacity,
        0.4s border;
      cursor: pointer;

      &:hover,
      &.active {
        opacity: 1;
        border-color: rgba(black, 0.5);
      }
    }
  }

  .title {
    h1 {
      margin: 0;
      padding: 0;
      font-size: 24px;
      font-weight: 400;
      color: #333;
      margin: 5px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .text {
    margin: 0 10px;
  }

  .dates {
    flex: 1;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    @media screen and (max-width: 900px) {
      justify-content: center;
      align-items: center;
    }
  }

  .date {
    display: flex;
    align-items: center;

    &.over {
      position: relative;

      h1,
      img {
        opacity: 0.2;

        animation-name: overOpacity;
        animation-delay: 1s;
        animation-duration: 0.5s;
        animation-fill-mode: both;
      }

      &:after {
        content: "Over";
        position: absolute;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 24px;
        font-weight: 600;

        transform: rotate(-5deg);

        animation-name: overOverlay;
        animation-delay: 2s;
        animation-duration: 2s;
        animation-fill-mode: both;
      }
    }

    img {
      height: 50px;
      width: 50px;
    }

    h1 {
      display: flex;
      flex-direction: column;

      font-size: 21px;
      font-weight: 400;

      span {
        margin-left: auto;
        font-weight: 600;
      }
    }
  }
}

@keyframes overOverlay {
  from {
    opacity: 0;
    transform: scale(2) rotate(-15deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(-5deg);
  }
}

@keyframes overOpacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}
