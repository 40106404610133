.RegisterNow-container {
  background-color: #018b9d;

  .RegisterNow-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .RegisterNow-contents {
    height: 100%;

    padding: 15px;

    .largeTitle {
      display: flex;
      margin: 10px auto;
      justify-content: center;
      align-items: center;
      font-size: 21px;
      button {
        border: 2px solid white;
      }
    }

    #title {
      text-align: center;
      color: white;
      padding-top: 15px;
      margin-top: 0px;

      @media screen and (max-width: 900px) {
        font-size: 1.25rem;
      }
    }
    #list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: auto;
      width: 95%;

      @media screen and (max-width: 900px) {
        flex-direction: column;
        text-align: center;
        width: 90%;
      }

      button {
        border: 1px solid white;

        padding: 10px 10px;
        font-size: 15px;
        @media screen and (max-width: 900px) {
          margin-top: 10px;
          width: 100%;
        }
      }
    }

    #note {
      color: white;
      text-align: center;
      font-size: 1rem;
      padding: 15px;
      margin: 0px;
    }
  }
}
a.PreRegisterButton,
.PreRegisterButton.nobg {
  background: transparent;
}

.WithBg {
  background-size: cover;
  background-image: url("./background.jpg");
}

.Vertical {
  flex-direction: column;

  .buttons {
    flex-wrap: wrap;
  }
}

.PreRegisterButton {
  button {
    color: #018b9d;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    transition: transform 0.4s ease;
    transform: scale(1) rotate(0deg);

    h1 {
      font-size: 21px;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
  }

  button:hover {
    transform: scale(1.5) rotate(2deg);
  }

  &.nobg button:hover {
    transform: scale(1.5) rotate(-2deg);
  }
}
