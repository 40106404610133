@import "styles/colors.scss";

.TeamsShowcase2 {
  background-color: $secondary;
  padding: 15px;
  box-sizing: border-box;

  h2 {
    color: white;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  .list-big {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    #last-box {
      max-height: 75px;

      @media screen and (max-width: 900px) {
        max-height: fit-content;
      }
    }

    > a,
    .box {
      margin: 15px;
      width: calc(100% / 4 - 30px);

      @media screen and (max-width: 900px) {
        width: 100%;
        height: auto;
        margin: 10px 0px;
      }
    }
    .big-box {
      margin: 15px;
      width: calc(100% / 2 - 30px);
      max-height: 20vh;
      height: auto;
      padding: 20px;
      justify-content: space-between;

      .more-details {
        font-weight: 700;
        margin-left: 10px;
        color: $secondary;
        margin: 0 10px 0 0;

        @media screen and (max-width: 900px) {
          display: flex;
          justify-content: center;
        }
      }

      @media screen and (max-width: 900px) {
        width: 100%;
        height: auto;
        margin: 10px 0px;
        text-align: center;
      }
    }

    .TeamBox {
      width: 100%;
      height: 100%;
      min-height: 150px;
    }
  }

  .big-box {
    height: 150px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }

    button {
      margin-top: 10px;
      margin-left: auto;
    }

    background-color: white;
    padding: 5px;
  }

  .box {
    height: 150px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }

    button {
      margin-top: 10px;
      margin-left: auto;
    }

    background-color: white;
    padding: 5px;
  }

  .filler {
    flex: 1;
  }
}
